<template>
  <PaymentCardBase :title="title" :active="active">
    <template v-slot:body>
      <section class="px-4">
        <WalletCard
          :size="`sm`"
          :text-position="`left`"
          ribbon=""
          :title-prefix="currencySymbol"
          :title="currentBalance"
          :subtitle="packageTitle"
        />
      </section>

      <section class="px-8 mt-6">
        <feature-icon-typography
          v-for="x in facilityTexts"
          :key="x"
          :text="x"
          icon="fas fa-check"
        />
        <!-- <feature-icon-typography
          text="First 30 mins free"
          icon="fas fa-check"
        />
        <feature-icon-typography text="Price protection" icon="fas fa-check" />
        <feature-icon-typography text="Cancel anytime" icon="fas fa-check" /> -->
      </section>

      <section>
        <div class="grid grid-cols-2 mt-2 border-t divide-x">
          <div class="flex items-center justify-center px-4 py-6">
            <i class="fas fa-wallet text-oGreen text-28px"></i>
            <div class="ml-4">
              <oto-typography
                class="block "
                text="Pass Balance"
                variant="three"
              />
              <oto-typography
                class="block"
                :text="`${currencySymbol}${currentBalance}`"
                variant="one"
              />
            </div>
          </div>

          <div class="flex items-center justify-center px-4 py-6">
            <i class="fas fa-bicycle text-oOrange text-28px"></i>
            <div class="ml-4">
              <oto-typography
                class="block "
                text="Ride Taken"
                variant="three"
              />
              <oto-typography
                class="block"
                :text="`${totalTrips}`"
                variant="one"
              />
            </div>
          </div>
        </div>

        <div class="grid grid-cols-2 border-t border-b divide-x">
          <div class="flex items-center justify-center px-4 py-6">
            <i class="fas fa-calendar-alt text-oTeal text-28px"></i>
            <div class="ml-4">
              <oto-typography
                class="block "
                text="Expiry Date"
                variant="three"
              />
              <oto-typography
                class="block"
                :text="`${expiryDate}`"
                variant="one"
              />
            </div>
          </div>

          <!-- <div class="flex items-center justify-center px-4 py-6" /> -->
          <div class="flex items-center justify-center px-4 py-6">
            <i class="fas fa-money-check text-oOrange text-28px"></i>
            <div class="ml-4">
              <oto-typography
                class="block "
                text="Total Spent"
                variant="three"
              />
              <oto-typography
                class="block"
                :text="`${currencySymbol}${totalSpent}`"
                variant="one"
              />
            </div>
          </div>
        </div>
      </section>
    </template>
  </PaymentCardBase>
</template>

<script>
export default {
  name: 'PaymentCardPassBlock',
  components: {
    WalletCard: () => import('@/components/cards/WalletCard.vue'),
    FeatureIconTypography: () =>
      import('@/components/typography/FeatureIconTypography.vue'),
    PaymentCardBase: () => import('./PaymentCardBaseBlock'),
    // PaymentCardPassInfo: () => import('./PaymentCardPassInfoBlock.vue'),
    OtoTypography: () => import('@/components/ui/OtoTypography'),
  },
  props: {
    title: {
      type: [String, Number],
      required: false,
    },
    active: {
      type: Boolean,
      required: false,
      default: false,
    },
    currencySymbol: {
      type: String,
      required: false,
      default: '$',
    },
    currentBalance: {
      type: [String, Number],
      required: false,
      default: 0,
    },
    totalSpent: {
      type: [Number, String],
      required: false,
      default: 0,
    },
    totalTrips: {
      type: [Number, String],
      required: false,
      default: 0,
    },
    expiryDate: {
      type: [Number, String],
      required: false,
      default: 0,
    },
    facilities: {
      required: false,
    },
    packageTitle: {
      type: [Number, String],
      required: false,
      default: 0,
    },
  },
  computed: {
    facilityTexts() {
      return this.facilities.map((x) => x.title)
    },
  },
}
</script>

<style lang="scss" scoped></style>
